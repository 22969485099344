.footer-menu {
	background-color: $primary;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 64px;
	display: flex;
	justify-content: space-between;
	padding: $s-size $xl-size;

	.icon {
		width: 35px;
		height: 35px;
	}

	.icon-small {
		width: 25px;
		height: 25px;
	}
}

.footer-menu-modal {
	width: 90%;
	background-color: white;
	padding: $m-size;
	border-radius: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $m-size;

	.footer-menu-modal__section {
		display: flex;
		flex-direction: column;
		gap: $xs-size;
	}

	.footer-menu-modal__buttons {
		width: 100%;
		text-decoration: none;

		button {
			width: 100%;
			height: 50px;
			background-color: white;
	        color: $blue;
	        border-radius: 3rem;
	        border: 0.2rem solid $blue;
	        font-size: $font-size-small;
	        padding: $xs-size $m-size;
	        text-decoration: none;
	        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);

	        display: flex;
	        gap: $s-size;
	        justify-content: center;
		}

		button.active {
			background-color: $blue;
			color: white;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE

}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .footer-menu {
    	display: none;
    }
}


@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
    .footer-menu {
    	display: none;
    }
}

@media screen and (min-width: 1812px) { //Desktop Wide
    .footer-menu {
    	display: none;
    }
}