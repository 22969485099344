
.history-record {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
    font-size: $font-size-med;
    border-radius: $l-size;

    .history-details {
        display: flex;
        flex-direction: row;
        gap: $l-size;
    }

    .history-text {
        .history-text__title {
            font-size: $font-size-large;
            font-weight: bold;
            color: $primary;
            margin: 0;
        }

        .history-text__subtitles {
            font-size: $font-size-small;
            color: $secondary;
        }

        .history-text__date {
            display: flex;
            flex-direction: row;
            gap: $s-size;
            
            font-size: $font-size-small;
            color: $secondary;
        }
    }

    .history-expand {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .history-expand-button {
            background: none;
            padding: 1rem;
            border: 0.2rem solid transparent;
            height: 50%;
            padding-left: 3rem;
            padding-right: 3rem;
            
        }
    }
    
    .history-icon {
        padding: 1rem;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background: none
    }
}

.history-record:hover{
    background-color: lighten($light-blue, 63%);

    .history-expand-button {
        color: $blue;
        border-radius: 3rem;
        background: white;
        border: 0.2rem solid $blue;
        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
    }
}
