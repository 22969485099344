.input {
    border: 1px solid $hr-grey;
    height: 5.5rem;
    font-size: $font-size-large;
    font-weight: 300;
    padding: $s-size;
    border-radius: 30px;
}

.input--large {
    padding-left: $xl-size;
    padding-right: $xl-size;
    width: 47rem;
}

.input--submit {
    width: 47rem;
    background: $blue;
    color: white;
    cursor: pointer;
    margin-top: $s-size;
}

.input-container {
    position: relative;

    .input {
        width: 100%;
        padding-left: $xl-size;
        padding-right: $xl-size;
    }
}

.select {
    @extend .input;
}

.textarea {
    @extend .input;
    height: 10rem;
}

.input__form {
    width: 100%;
    height: 4rem;
    min-width: 100px;
    font-size: $font-size-med;
}

.input__form-small {
    height: 4rem;
    margin: 0.1rem;
}

.input-checkbox {
    border: 1px solid $grey;
    border-radius: 2rem;
    padding: 0.8rem 2rem;
    margin: 0 2rem 0 0;
    width: 20%;
    height: 10%;
}



.input__product-min-year {
    margin: 1rem;
    border: 1px solid $blue;
    border-radius: 2rem;
    padding: 0.5rem 2rem;
    align-items: center;
}

.input__product-calc-year {
    background: $grey;
    box-shadow: 0rem 0rem 15rem rgba(66, 79, 101, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;
    margin: 1rem;
}

.input-transparent {
    border: none;
    align-items: center;
    width: 4rem;
    color: $blue;
    height: 100%;
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .input--submit {
        width: 100%;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .input--submit {
        width: 100%;
    }
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide
}



