@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .hide-for-mobile {
        display: none;
    }

    .sidebar {
        display: none;
    }

    .content-page {
        margin-left: 0px;
    }

    .navbar.navbar-absolute {
        padding-left: 0px;
    }

    .row {
        gap: $m-size;
    }

    .input-select-container {
        min-width: 100px;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .hide-for-tablet {
        display: none;
    }
}


@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
    .hide-for-desktop {
        display: none;
    }
}

@media screen and (min-width: 1812px) { //Desktop Wide
    .hide-for-desktop-wide {
        display: none;
    }
}