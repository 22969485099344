.notification {
	box-shadow: 0 0 3px #999;

	.title {
		font-weight: 600;
		font-size: $font-size-large;
	}

	.message {
		font-weight: 400;
		font-size: $font-size-small;
		color: $secondary;
	}
}

.notification-success {
	background-color: #EBF6EB;
	border: 1.5px solid #31AA27;
	border-radius: 25px;
	color: $primary;
	opacity: 1;

	&:before {
    	content: url('../../images/green-check.svg');
  	}
}

.notification-error {
	background-color: #FFF0F0;
	border: 1.5px solid #F93232;
	border-radius: 25px;
	color: $primary;
	opacity: 1;

	&:before {
    	content: url('../../images/red-cross.svg');
  	}
}

.notification-info {
	background-color: #F1F6FC;
	border: 1.5px solid #629DF5;
	border-radius: 25px;
	color: $primary;
	opacity: 1;

	&:before {
    	content: url('../../images/info-blue.svg');
  	}
}