.contracts-action__div {
	display: flex;
	gap: $s-size;

	img {
		width: 30px;
		height: 30px;
	}
}

#contract-form {
	.details-page__form-elements {
		display: flex;
		flex-direction: column;
		gap: $xxs-size;

		.activation-date-picker {
			width: 100%;
			border: 1px solid $hr-grey;
			border-radius: 30px;
			padding: $xxs-size $s-size;
		}

		.react-datepicker-popper {
			z-index: 999;
		}

		.activation-date-calendar {
			font-size: 1em;

			.react-datepicker__header {
		  		padding-top: 0.8em;
			}

			.react-datepicker__month {
				margin: 0.4em 1em;
			}

			.react-datepicker__day-name, .react-datepicker__day {
				width: 1.9em;
				line-height: 1.9em;
				margin: 0.166em;
			}

			.react-datepicker__current-month {
				font-size: 1em;
			}

			.react-datepicker__navigation {
				top: 1em;
				line-height: 1.7em;
				border: 0.45em solid transparent;
			}
		}
	}
}

.contracts-table__header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: $l-size;
	overflow-x: auto;
	white-space: nowrap;

	.header-vin {
		display: flex;
		flex-direction: row;
		gap: $s-size;
		align-items: center;

		h3 {
			color: $light-blue;
			align-items: center;
		}

		input {
			border: 1px solid $hr-grey;
			padding: $s-size;
			border-radius: 30px;
			height: 80%
		}

		button {
			text-decoration: none;
		    background: $blue;
		    color: white;
		    padding: 0.7rem 2.5rem;
		    border-radius: 3rem;
		    display: flex;
		    flex-direction: row;
		    gap: $xs-size;
		    border: none;
		    justify-content: center;
		    height: 100%;
		}
	}

	.header-reset {
		text-decoration: none;
	    background: $blue;
	    color: white;
	    padding: 0.7rem 2.5rem;
	    border-radius: 3rem;
	    display: flex;
	    flex-direction: row;
	    gap: $xs-size;
	    border: none;
	    justify-content: center;
	    height: 100%;
	    white-space: nowrap;
	}

	.header-date {
		display: flex;
		flex-direction: row;
		gap: $s-size;
		align-items: center;

		.date-filter {
			text-decoration: none;
		    background: $blue;
		    color: white;
		    padding: 0.7rem 2.5rem;
		    border-radius: 3rem;
		    display: flex;
		    flex-direction: row;
		    gap: $xs-size;
		    border: none;
		    justify-content: center;
		    height: 100%;
		    white-space: nowrap;
		}

		.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
			font-size: $m-size;
			align-items: center;
		}
	}

	.contract-table__header-group {
		display: flex;
		gap: $l-size;
		
		.header-filters {
			display: flex;
			flex-direction: row;
			gap: $xl-size;
		}
	}
}

.contracts-override {
	display: flex;
	flex-direction: row;
	margin: 0rem 3rem 0rem 0rem;
	gap: $s-size;

	.override-cancel__button {
		background: red;
		color: white;
		border-radius: 3rem;
		border: 0.2rem solid red;
		height: 100%;
		font-size: $font-size-small;
		padding: $xs-size $l-size;
		text-decoration: none;
		box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
	}

	.override-approve__button {
		background: green;
		color: white;
		border-radius: 3rem;
		border: 0.2rem solid $blue;
		height: 100%;
		font-size: $font-size-small;
		padding: $xs-size $l-size;
		text-decoration: none;
		display: flex;
		gap: $s-size;
		box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
	}

}


@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .contracts-table__header {
    	flex-direction: column;
    	gap: $s-size;

    	.header-vin {
    		width: 100%;
    	}

    	.header-date {
    		width: 100%;
    	}
    }
}




