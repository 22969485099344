.pagination-div {
    padding: $s-size $l-size;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    background-color: white;
    box-shadow: 0 0 15px rgba(29,49,80,.1);

    .pageButtons {
        display: flex;
        overflow-x: auto;
        gap: $xs-size;
    }
      
    .pageBtn {
        background-color: transparent;
        color: black;
        border-radius: 50%;
        min-width: 30px;
        height: 30px;
        font-weight: normal;
        font-size: $font-size-small;
        border: none;
        padding: 0px;
    }

    .activeBtn {
        background-color: $blue;
        color: white;
        border: none;
    }
}