.filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: $m-size;
    height: 100%;
}

.filter-roles {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $m-size;
    overflow-x: auto;
    white-space: nowrap;
}

.filter-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $m-size;
}

.filter-buttons {
    color: $secondary;
    padding: $xxs-size $m-size;
    border: none;
    background-color: $background-color;
}

.filter-buttons.active {
    background: white;
    color: $blue;
    border-radius: 3rem;
    border: 0.2rem solid $blue;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .filter-actions {
        gap: $xxs-size;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}
