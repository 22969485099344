.header {
    background: white;
    padding: $m-size;
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 1024;
    box-shadow: 0px 0px 35px rgba(29, 49, 80, 0.1);
}

.header__content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    button {
        background: white;
        color: $blue;
        border-radius: 3rem;
        border: 0.2rem solid $blue;
        height: 100%;
        font-size: $font-size-small;
        padding: $xs-size $m-size;
        text-decoration: none;
        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
    }
}

.header__title {
    color: $primary;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: $font-size-large;

    div {
        display: flex;
        gap: $xs-size;
    }

    .sub {
        color: $secondary;
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .header__content {
        justify-content: center;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}