.details-page__header {
	display: flex;
	justify-content: space-between;
	height: 100%;
	gap: $m-size;

	button {
		border-radius: 50%;
		background-color: transparent;
		border: none;
	}

	button:disabled {
		color: #d7d7d7;
	}
}

.details-page__menu {
	display: flex;
	justify-content: flex-end;
	gap: $m-size;
	overflow-x: auto;
	white-space: nowrap;
}

.details-page__summary {
	background: white;
    border-radius: 2rem;
    padding: $xl-size;
    display: flex;
    flex-direction: column;
    gap: $xl-size;
    justify-content: flex-start;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.05);
    height: 100%;
}

.details-page_summary-info {
	display: flex;
	flex-direction: column;
	gap: $xs-size;

	.active { 
		color: #31AA27;
	}

	.inactive {
		color: red;
	}
}

.details-page__summary-image {
	display: flex;
	justify-content: center;
	padding: 5rem;
	border: 1px solid #DBE1EC;
	background-color: #F9FBFE;
	border-radius: 50%;
}

.details-page__summary-image-mini {
	display: flex;
	justify-content: center;
	padding: 1.5rem;
	width: 6rem;
	height: 6rem;
	border: 1px solid #DBE1EC;
	background-color: #FFFFFF;
	border-radius: 50%;
}

.details-page__summary-highlights {
	display: flex;
	flex-direction: column;
	justify-content: center;

	b {
		font-size: $font-size-large;
	}

	p {
		margin: 0px;
		color: $secondary;
		font-size: $font-size-small;
	}
}

.details-page__form {
	background: white;
    border-radius: 2rem;
    padding: $l-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.05);

    form {
    	display: flex;
    	flex-direction: column;
    	gap: $m-size;
    }

    .form-button {
    	background: $blue;
	    color: white;
	    border-radius: 3rem;
	    border: 0.2rem solid $blue;
	    height: 100%;
	    font-size: $font-size-small;
	    padding: $xs-size $l-size;
	    text-decoration: none;
	    display: flex;
	    gap: $s-size;
	    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
    }

    .add-all__button {
	    padding: 0.2rem 1rem;
    }

    .assigned-dealers__div {
    	display: flex;
    	gap: $s-size;
    	flex-wrap: wrap;

    	.assigned-dealers__button {
    		display: flex;
    		height: 100%;
	    	background: white;
		    color: $blue;
		    border-radius: 3rem;
		    border: 0.2rem solid $blue;
		    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
	    }
    }

    .available-dealers__div {
    	display: flex;
    	flex-wrap: wrap;
    	gap: $s-size;


    	.available-dealers__button {
    		width: 30%;
    		background-color: transparent;
    		color: $secondary;
    		border: none;
    		box-shadow: none;
    		gap: $m-size;
    	}
    }
    
    .date-picker {
    	input {
    		height: 4rem;
    	}
    }
}

.details-page__form-elements {
	display: flex;
	
	label {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: $xxs-size;
	}

	.btn-group {
		button {
			display: flex;
			justify-content: center;
			background-color: white;
			background: white;
    		color: $blue;
    		border: 0.2rem solid $blue;
		}

		button:first-child {
			border-right: none;
		}

		button.active {
			background-color: $blue;
			color: white;
		}
	}
}

.details-page__footer {
	background: white;
	padding: $s-size $l-size;
	display: flex;
	flex-direction: flex-end;
	width: 100%;
	height: 64px;
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .details-page__menu {
    	justify-content: flex-start;
    	overflow-x: auto;
    }

    .details-page__form {
    	padding: $m-size;

	    .available-dealers__div {
	    	.available-dealers__button {
	    		width: 100%;
	    	}
	    }
	}
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .details-page__form {
	    .available-dealers__div {
	    	.available-dealers__button {
	    		width: 40%;
	    	}
	    }
	}
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}