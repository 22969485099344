.ReactModalPortal > div {
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity 200ms ease-in-out;
    z-index: 9000;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-product {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 90%;
    height: 90%;
    overflow: 'visible';
    display: flex;
    flex-direction: column;
    gap: $m-size;

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    button {
        background: $blue;
        color: white;
        border-radius: 3rem;
        border: 0.2rem solid $blue;
        font-size: $font-size-small;
        padding: $xs-size $l-size;
        text-decoration: none;
        display: flex;
        gap: $s-size;
        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
    }

    .assigned-products__div {
        display: flex;
        gap: $s-size;
        flex-wrap: wrap;

        .assigned-products__button {
            display: flex;
            background: white;
            color: $blue;
            border-radius: 3rem;
            border: 0.2rem solid $blue;
            box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
        }
    }

    .available-products__div {
        display: flex;
        flex-wrap: wrap;
        gap: $s-size;

        .available-products__button {
            width: 30%;
            background-color: transparent;
            color: $secondary;
            border: none;
            box-shadow: none;
            gap: $m-size;
            text-align: left
        }
    }
}

.modal-pricing-position {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 90%;
    height: 90%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-size;

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: $s-size;
        overflow: auto;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .group {
        display: flex;
        gap: $m-size;
        justify-content: center;

        .label-group {
            display: flex;
            gap: $m-size;

            span {
                white-space: nowrap;
            }
        }
    }

    .pricing-position-form {
        display: flex;
        gap: $xl-size;
        justify-content: flex-start;

        .form-section {
            display: flex;
            gap: $xs-size;

            span {
                white-space: nowrap;
            }
        }
    }

    .additional-items-form {
        display: flex;
        justify-content: center;
        gap: $m-size;

        .group {
            display: flex;
            gap: $xs-size;

            span {
                white-space: nowrap;
            }

            .link__form-add {
                font-size: $font-size-small;
            }
        }
    }

    .button-no-background {
        background-color: white;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}

.modal-transfer-owner {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 90%;
    height: 90%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-size;

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: $s-size;
        overflow: auto;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    form {
        gap: $s-size;
        display: flex;
        flex-direction: column;

        .row {
            width: 100%;
        }

        .form-button {
            background: $blue;
            color: white;
            border-radius: 3rem;
            border: 0.2rem solid $blue;
            height: 100%;
            font-size: $font-size-small;
            padding: $xs-size $l-size;
            text-decoration: none;
            display: flex;
            gap: $s-size;
            box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
        }
    }

    .button-no-background {
        background-color: white;
    }

    .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: $s-size;
    }
}

.modal-territory, .modal-additional-item {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 50%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-size;

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: $s-size;
        overflow: auto;
    }

    .modal-footer {

    }

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: $m-size;

        .details-button{
            display: flex;
            flex-direction: column;
        
            .btn-group {
                button {
                    display: flex;
                    justify-content: center;
                    background-color: white;
                    background: white;
                    color: $blue;
                    border: 0.2rem solid $blue;
                    font-size: $font-size-small;
                    padding: $xs-size $l-size;
                    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
                }

                button:first-child {
                    border-right: none;
                }

                button.active {
                    background-color: $blue;
                    color: white;
                }
            }
        }
    }

    .button-no-background {
        background-color: white;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}

.modal-button {
    position: absolute;
    top: 95px;
    right: 110px;
    background-color: white;
    border-radius: 1rem;
    width: 4.5rem;
    border: 1px solid $blue;
}

.modal-delete, .modal-contract__action {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 40%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-size;


    .modal-body {
        display: flex;
        flex-direction: column;
        gap: $s-size;
        overflow: auto;
    }

    .modal-footer {
        justify-content: center;
        gap: $s-size;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .button-no-background {
        background-color: white;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}




.modal-contract__filters {
    background: white;
    outline: none;
    padding: $l-size;
    border-radius: 2rem;
    width: 60%;
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $s-size;


    .modal-body {
        display: flex;
        flex-direction: column;
        gap: $s-size;
        overflow: auto;
    }

    .modal-footer {
        justify-content: center;
        gap: $s-size;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;

        .close {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .button-no-background {
        background-color: white;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
    }
}







.model-button__yes {
    background: red;
    border-radius: 3rem;
    display: flex;

}

.model-button__no {
    background: white;
    border-radius: 3rem;
    display: flex;
}


@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .modal-delete, .modal-contract__action, .modal-contract__filters {
        width: 90%;

        .modal-footer {
            button {
                padding: $s-size $l-size;
            }
        }
    }

    .modal-product {
        width: 95%;
        height: 95%;
        padding: $m-size;

        .available-products__div {
            .available-products__button {
                width: 100%;
                padding: $xs-size 0px;
            }
        }
    }

    .modal-pricing-position {
        width: 95%;
        height: 95%;
        overflow-y: auto;
        padding: $m-size;

        .group {
            flex-wrap: wrap;
            gap: $xs-size;
        }

        .additional-items-form {
            .group {
                flex-wrap: nowrap;
            }
        }

        .pricing-position-form {
            flex-direction: column;
            gap: $xs-size;
        }

        .additional-items-form {
            flex-direction: column;
            gap: $xs-size;
        }

        .footer {
            width: 100%;

            .link__form-add {
                width: 100%;
            }
        }

        .modal-footer {
            display: flex;
            gap: $xs-size;
            justify-content: center;

            span {
                margin-right: 0px;
            }
        }

        .input-select-container {
            width: 100%;
        }
    }

    .modal-transfer-owner {
        width: 95%;
        height: 95%;
        overflow-y: auto;
        padding: $m-size;

        .modal-footer {
            display: flex;
            gap: $xs-size;
            justify-content: center;

            span {
                margin-right: 0px;
            }
        }
    }

    .modal-territory, .modal-additional-item {
        width: 95%;
    }
}



@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .modal-delete, .modal-contract__action, .modal-contract__filters {
        width: 60%;
    }
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}
