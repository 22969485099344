.list-table {
    display: flex;
    flex-direction: column;
}

.list-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12rem;
}

.list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12rem;
    font-size: 1.2rem;
}