.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: fixed;
    z-index: 1029;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: $m-size 0rem;

    .ps-menu-icon.ps-active {
        background-color: #6B9FED;
        border-radius: 40%;
    }
}