.link {
    text-decoration: none;
    color: $primary;
}

.link__form-add {
    text-decoration: none;
    background: $blue;
    color: white;
    padding: 0.7rem 2.5rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
    gap: $xs-size;
    border: none;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.link-spinner {
    display: flex;
    gap: $xs-size;
    align-items: center
}

.link__form-add.disabled {
    background: $grey;
}

.link-button {
    text-decoration: none;
    background: $secondary;
    color: white;
    padding: 0.7rem 2.5rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
    gap: $xs-size;
    border: none;
    justify-content: center;
    height: 100%;
}



@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .link__form-add {
        padding: $xs-size $m-size;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop 
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}