.footer__cancel-button {
    background: white;
    color: $blue;
    border-radius: 3rem;
    border: 0.2rem solid $blue;
    height: 100%;
    font-size: $font-size-small;
    padding: $xs-size $xl-size;
    text-decoration: none;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}

.footer__cancel-contract {
    background: red;
    color: white;
    border-radius: 3rem;
    border: 0.2rem solid red;
    height: 100%;
    font-size: $font-size-small;
    padding: $xs-size $xl-size;
    text-decoration: none;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}

.footer__save-button {
	background: $blue;
    color: white;
    border-radius: 3rem;
    border: 0.2rem solid $blue;
    height: 100%;
    font-size: $font-size-small;
    padding: $xs-size $m-size;
    text-decoration: none;
    display: flex;
    gap: $s-size;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}