.table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: $s-size;
    flex-grow: 1;

    table {
        width: 100%;
    }

    tbody {
        border-bottom: 1px solid $table-grey;
    }

    tr {
        border-bottom: none;
    }

    .table-div {
        padding: $m-size;
        overflow-x: auto;
        height: 100%;
    }

    margin-bottom: 0px;

    .pagination {
        display: flex;
        background-color: white;
        height: 64px;
    }

    .pagination__table {
        display: flex;
        padding: $s-size $l-size;
        justify-content: flex-end;
        width: 100%;
        box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);

        .pagination__page-button {
            border: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: transparent;
        }

        .pagination__page-button.active {
            background-color: #629DF5;
            color: white;
        }
    }

    .table-cell__div {
        padding: $s-size $m-size;
        display: flex;
        justify-content: flex-start;
        gap: $xxs-size;

        .button {
            cursor: pointer;
        }
    }

    .table-cell__div.flex-center {
        justify-content: center;
    }

    .table-cell__div.pointer {
        cursor: pointer;
    }

    .table-cell__dealer {
        padding: $s-size 20rem $s-size $m-size;
        display: flex;
        justify-content: flex-start;
        gap: $xxs-size;
    }

    .table-header {
        border-left: 1px solid $table-grey;
        border-right: 1px solid $table-grey;
        border-top: 1px solid $table-grey;
        border-bottom: 1px solid $table-grey;
        padding: $xs-size $m-size;
        color: $primary;
        font-weight: 500;

        .table-header__div {
            display: flex;
            justify-content: space-between;
            white-space: nowrap;
            gap: $xs-size;
        }

        .table-header__icons {
            display: flex;
            justify-content: center;
            flex-direction: column;

            div {
                display: flex;
                gap: $xxs-size;
            }
        }

        .table-header__vertical-line {
            width: 1px;
            height: 20px;
            background-color: $hr-grey;
        }

        .table-header__icon-img {
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }

    .table-header.filterable {
        min-width: 150px;
    }

    .table-header.flex-end:last-child {
        .table-header__div {
            justify-content: center;
            gap: 0;
        }
    }

    td {
        border-left: 1px solid $table-grey;
        border-right: 1px solid $table-grey;
    }
}

.table.mini {
    flex-grow: 0;

    .table-div {
        padding: 0px;
    }
}

.table.mini.overflow {
    overflow-y: auto;
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .table {
        .table-div {
            padding: $s-size;
        }
    }

    .table.mini.overflow {
        min-height: 100px;
    }

    .table.contract-products {
        max-height: 500px;

        .table-div {
            padding: 0px;
        }
    }

    .table .pagination__table {
        justify-content: center;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .table.contract-products {
        max-height: 500px;
    }
}


@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
    .table.contract-products {
        max-height: 500px;
    }
}

@media screen and (min-width: 1812px) { //Desktop Wide
    .table.contract-products {
        max-height: 500px;
    }
}

