* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    color: $primary;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: $m-size;
    line-height: 1.6;
    margin: 0px;
}

button {
    cursor: pointer;
}
button:disabled {
    cursor: default;
}

.is-active {
    font-weight: bold
}

.vertical-align-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.input-select-container {
    min-width: 350px;
}

hr {
    border: 1px solid $hr-grey;
}

.badge {
    padding: $xs-size $m-size;
    font-size: $font-size-xs;
    font-weight: normal;
    border-radius: 2rem;
}

.badge__new {
    background-color: #EBF6EB;
    color: #31AA27;
}

.badge__used {
    background-color: #FFF4E4;
    color: #E99518;
}

.badge__user-role {
    background-color: #EDEDFE;
    color: #605DEC;
}

.badge__terms {
    background-color: white;
    color: #828DA1;
    border: 1px solid #C5D0E4;
}

.badge__finalized {
    background-color: #EBF6EB;
    color: black;
    border: 1px solid #31AA27;
}

.badge__cancelled {
    background-color: #FFF0F0;
    color: black;
    border: 1px solid #F93232;
}

.badge__pending {
    background-color: #F1F6FC;
    color: black;
    border: 1px solid #629DF5;
}

.badge__transferred {
    background-color: #EDEDFE;
    color: black;
    border: 1px solid #605DEC;
}