// Colors
$grey: #C5D0E4;
$blue: #629DF5;
$dark-blue: #1D3150;
$off-white: #f7f7f7;
$light-blue: #464b5e;
$background-color: #F9FBFE;
$primary: #1D3150;
$secondary: #7B879D;
$hr-grey: #DBE1EC;
$table-grey: #C5D0E4;

//Font Sizes
$font-size-xxs: 0.5rem;
$font-size-xs: 1.2rem;
$font-size-small: 1.4rem;
$font-size-med: 1.6rem;
$font-size-large: 1.8rem;

// Spacing
$xxs-size: 0.5rem;
$xs-size: 1rem;
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;

// Navbar
$navbar-margin-a:                15px 0px;

$padding-social-a:               10px 5px;

$navbar-margin-a-btn:            15px 0px;
$navbar-margin-a-btn-round:      16px 0px;

$navbar-padding-a-icons:         6px 15px;
$navbar-margin-a-icons:          6px  3px;

$navbar-padding-base:            0.625rem;
//$navbar-margin-brand:             5px  0px;

$navbar-margin-brand-icons:      12px auto;
$navbar-margin-btn:              15px  3px;