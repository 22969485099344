.icon-input {
    position: absolute;
    top: 20px;
    left: 20px;
    color: grey;
}

.icon-button {
    position: absolute;
    top: 15px;
    left: 430px;
    background: none;
    border: none
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .icon-button {
        left: 320px;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .icon-button {
        left: 600px
    }
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
}

@media screen and (min-width: 1812px) { //Desktop Wide 
}