.dashboard-content {
    display: flex;
    flex-direction: column;
    gap: $s-size;
}

.dashboard-spaced {
    display: flex;
    justify-content: space-between;
}

.dashboard-kpi {
    display: flex;
    flex-direction: row;
    background: white;
    border-radius: 3rem;
    padding: $m-size;
    gap: $s-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);

    .chart {
        width: 100px;
        height: 100px;
    }

    .metrics {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: $s-size 0px;

        div {
            display: flex;
            flex-direction: column;
        }

        .subtext {
            color: $secondary;
            font-size: $font-size-xs;
        }
    }
}

.dashboard-sales-summary {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 3rem;
    padding: $l-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);
    gap: $m-size;

    .chart {
        height: 400px;
    }
}

.dashboard-kpi__spacing {
    line-height: 1rem;
    align-items: center;

    padding: $m-size;
}

.dashboard-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
        display: flex;
        gap: $xxs-size;
    }

    h3 {
        margin-bottom: 0px;
    }
}

.dashboard-contracts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 3rem;
    padding: $l-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);
    gap: $xs-size;
    height: 100%;

    .chart {
        height: 200px;
        display: flex;
        justify-content: center;
    }

    .legend {
        display: flex;
        justify-content: space-between;

        .entity {
            display: flex;
            gap: $xs-size;

            .notch {
                display: flex;
                flex-direction: column;
                justify-content: center;

                div {
                    width: 15px;
                    height: 10px;
                    border-radius: 40%;
                }
            }

            .label {
                display: flex;
                flex-direction: column;

                .name {
                    font-size: $font-size-small;
                }

                .value {
                    font-size: $font-size-small;
                    font-weight: bold;
                }
            }   
        }
    }

    .btn-group {
        button {
            display: flex;
            justify-content: center;
            background-color: white;
            background: white;
            color: $blue;
            border-radius: 3rem;
            border: 0.2rem solid $blue;
            height: 100%;
            font-size: $font-size-small;
            padding: $xs-size $xl-size;
            text-decoration: none;
            gap: $s-size;
            box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
        }

        button:first-child {
            border-right: none;
        }

        button.active {
            background-color: $blue;
            color: white;
        }
    }
}

.dashboard-report {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 3rem;
    padding: 2.5rem;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);
    font-size: $font-size-small;
    gap: $m-size;

    .body {
        display: flex;
        flex-direction: column;
        gap: $m-size;

        .section {
            display: flex;
            justify-content: space-between;

            .chart {
                height: 30px;
                width: 100px;
            }

            .label {
                display: flex;
                flex-direction: column;

                .subtext {
                    color: $secondary;
                    font-size: $font-size-xs;
                }
            }
        }
    }
}

.dashboard-filters {
    display: flex;
    flex-direction: row;
    gap: $m-size;

    h3 {
        margin-bottom: 0px;
    }
}

.dashboard-filter__time {
    display: flex;
    gap: $xs-size;
    background: white;
    color: $blue;
    border-radius: 3rem;
    border: 0.2rem solid $blue;
    height: 100%;
    font-size: $font-size-small;
    padding: $xs-size $m-size;
    text-decoration: none;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);

}

.dashboard-filter__entity {
    background: white;
    border-radius: 50%;
    border: 0.2rem solid $hr-grey;
    padding: $xs-size $s-size;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}

.dashboard-report-button {
    background: $blue;
    color: white;
    border-radius: 3rem;
    border: 0.2rem solid $blue;
    height: 100%;
    font-size: $font-size-small;
    padding: $xs-size $m-size;
    text-decoration: none;
    display: flex;
    gap: $s-size;
    box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
}





