.login-layout {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}

.login-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.login-background {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    background: url('../../images/login-background-1.png');
    background-size: cover;
    color: white;
}

.login__title {
    margin: $xl-size;
}

.login__error {
    color: red;
    display: flex;
    justify-content: center;
    margin-top: $xs-size;
}

.login__subtitle {
    color: $secondary;
}

@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .login-content {
        width: 100%;
        padding: $m-size;

        form {
            margin: 4.8rem 0px;
            width: 100%;
        }
    }

    .login__subtitle {
        text-align: center;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .login-content {
        width: 100%;
        padding: $xl-size;

        form {
            margin: 4.8rem 0px;
            width: 100%;
        }
    }

    .login__subtitle {
        text-align: center;
    }
}


@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
    
}

@media screen and (min-width: 1812px) { //Desktop Wide
    
}