.loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.loader__image {
    height: 6rem;
    width: 6rem;
}