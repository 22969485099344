.form {
    display: flex;
    flex-direction: column;
    > * {
        margin-bottom: $m-size;
    }
    margin: $xl-size $xl-size 5rem $xl-size;
}  

.form__error {
    margin: 0 0 $m-size 0;
    font-style: italic;
    color: red;
}

.form__options {
    display: flex;
    justify-content: space-between;
    margin-bottom: $xl-size;
}

.form__remember {
    display: flex;
    gap: $xs-size;
}

.form__add-button {
    background: $blue;
    text-decoration: none;
    border-radius: 1.5rem;
    padding: 1rem;
    color: white;
}

.form-label {
    color: grey;
    margin-bottom: 0px;
}
