.navbar {
    height: 80px;

    &.navbar-absolute{
        position: fixed;
        width: 100%;
        padding-top: 10px;
        padding-left: 80px + 16px;
        z-index: 1025;
        background-color: white;
        box-shadow: 0px 0px 35px rgba(29, 49, 80, 0.1);
    }
}