.content-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    background: $background-color;
}


.content-page {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 8rem);
    height: 100%;
    margin-left: 8rem;

    .content {
        height: calc(100vh - 80px);
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .content-row {
            padding: $m-size $m-size 0 $m-size;
        }

        .content-row.flex-grow {
            flex-grow: 1
        }

        .content-row.flex-right {
            display: flex;
            justify-content: flex-end;
        }

        .content-row.footer {
            display: flex;
            background-color: white;
            padding: $s-size $l-size;
            justify-content: flex-end;
            gap: $s-size;
            height: 64px;
            margin-top: $s-size;
            box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);

            a {
                text-decoration: none;
            }
        }

        .content-row.contracts-footer {
            display: flex;
            background-color: white;
            padding: $s-size $l-size;
            justify-content: space-between;
            gap: $s-size;
            height: 64px;
            margin-top: $s-size;
            box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.1);

            a {
                text-decoration: none;
            }

            div {
                display: flex;
                gap: $s-size;
            }
        }

        .content-row.margin-bottom {
            margin-bottom: $m-size;
        }
    }
}

.content-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 5rem;

};

.content-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 5rem;
}

.content-spaced {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    
}

.content__panel-summary {
    background: white;
    border-radius: 2rem;
    padding: 5rem;
    margin: 2rem;
    width: 45rem;
    height: 50rem;
}

.content__panel-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.content__panel-form {
    background: white;
    border-radius: 2rem;
    padding: 5rem;
    margin: 2rem;
    width: 82rem;
    height: 65rem;
}


@media screen and (min-width: 320px) and (max-width: 480px) { //MOBILE
    .content-page {
        width: 100%;

        .content {
            height: calc(100vh - 80px - 64px);

            .content-row {
                padding: $xs-size $xs-size 0 $xs-size;
            }

            .content-row.footer {
                justify-content: center;
                padding: $xs-size $s-size;
            }

            .content-row.contracts-footer {
                padding: $xs-size $s-size;
                flex-direction: column;
                height: 122px;

                div {
                    justify-content: center;
                }
            }
        }
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .content-page {
        .content {
            height: calc(100vh - 80px);
        }
    }
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop

}

@media screen and (min-width: 1812px) { //Desktop Wide

}
