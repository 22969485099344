.button-no-background {
    border: none;
    background: $background-color;
    padding: 0px;
}

.button-import {
    background: $blue;
    color: white;
    padding: 1.05rem 2.5rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: row;
    gap: $xs-size;
    border: none;
    justify-content: center;
    height: 100%;
    font-size: 80%;
    
}

.button-round {
    padding: 1rem;
    border-radius: 4rem;
}