.settings-page__menu {
    display: flex;
    gap: $s-size;
    overflow: auto;

    .menu-buttons {
        color: $table-grey;
        padding: $xxs-size $l-size;
        border-radius: 3rem;
        border: 0.2rem solid $hr-grey;
        background: white;
        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
        white-space: nowrap;
    }

    .menu-buttons.active {
        background: white;
        color: $secondary;
        border-radius: 3rem;
        border: 0.2rem solid $hr-grey;
        box-shadow: 0px 0px 15px rgba(66, 79, 101, 0.1);
    }
}

.territory-management {
	display: flex;
    flex-direction: column;
    gap: $l-size;
    background: white;
    border-radius: 2rem;
    padding: $l-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.05);
    height: 100%;

    .territory-div {
        display: flex;
        justify-content: space-between;
        gap: $s-size;

        .group {
            width: 100%;
        }
    }
}

.contract-templates {
    display: flex;
    flex-direction: column;
    gap: $m-size;
	background: white;
    border-radius: 2rem;
    padding: $l-size;
    box-shadow: 0px 0px 15px rgba(29, 49, 80, 0.05);

    .contract-editor {
        display: flex;
        height: 1000px;
        
        .allotment-div {
            display: flex;
            height: 100%;
            overflow: auto;
        }
    }
}